import { AxiosInstance } from 'axios'
import { Census, FileUrl, Genders } from './types'
import { ADMIN_API } from './constants'

export const getCensus = async (http: AxiosInstance, params: { censusId: string }): Promise<Census> => {
  const { censusId } = params
  const res = await http.get(`${ADMIN_API}/census/${censusId}/form`)
  return res.data
}

export type CreateVoterParams = {
  censusId: string
  body: {
    id: string
    name: string
    surname: string
    email: string
    documentId: string
    prefixPhone: string
    phone: string
    weight?: number
    gender: Genders
  }
}
export const createVoter = async (http: AxiosInstance, params: CreateVoterParams) => {
  const { censusId, body } = params
  await http.post(`${ADMIN_API}/census/${censusId}/form`, body)
}

export type GetFileUrlParams = {
  censusId: string
  agentUuid: string
  fileName: string
}
export const getFileUrl = async (http: AxiosInstance, params: GetFileUrlParams): Promise<FileUrl> => {
  const { censusId, agentUuid, fileName } = params
  const res = await http.get(`${ADMIN_API}/census/${censusId}/form/${agentUuid}/${fileName}`)
  return res.data
}
